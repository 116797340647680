module.exports = permission => {
  return {
    VIEW_EXAM: [{ type: 'MINE' }, { type: 'ALL' }],
    EDIT_EXAMS: [{ type: 'MINE' }],
    ASSIGN_EXAMS: [{ type: 'ANYONE' }, { type: 'MYSELF' }],
    DELETE_EXAMS: [{ type: 'ALL' }, { type: 'MINE' }],
    DELETE_IMAGES: [{ type: 'ALL' }, { type: 'MINE' }],
    ANNOTATE_EXAMS: [{ type: 'ALL' }, { type: 'MINE' }],
    TAG_EXAMS: [{ type: 'ALL' }, { type: 'MINE' }],
    VIEW_QA_ASSESSMENT: [{ type: 'ALL' }, { type: 'MY_EXAMS' }],
    VIEW_INSIGHTS: [{ type: 'ALL' }, { type: 'MY_INSIGHTS' }],
    MANUALLY_TRANSFER_EXAM: [{ type: 'ALL' }, { type: 'MY_EXAMS' }],
    INTERPRET_EXAM: [{ type: 'ALL' }],
    QA_REVIEW: [{ type: 'ALL' }],
    ADVANCED_QA_SETTINGS: [{ type: 'ALL' }],
    CLINICAL_ADMIN_SETUP: [{ type: 'ALL' }],
    LICENSES: [{ type: 'ALL' }],
    RESET_EXAM: [{ type: 'ALL' }],
    PERFORM_EXAM: [{ type: 'MINE' }],
    EXPORT_EXAMS: [{ type: 'ALL' }, { type: 'MY_EXAMS' }],
  }[permission];
};
