import ReactPaginate from 'react-paginate';
import { Text, useColorMode } from '@chakra-ui/react';
import React from 'react';
import styled from 'styled-components';
import * as Icons from '../../../assets';
import './pagination.scss';

interface UserListFooterProps {
  totalCount: number;
  itemsPerPage: number;
  handlePageChange: (pageNumber: number) => void;
  currentPage: number;
}

const UserListFooter = ({ itemsPerPage, totalCount, currentPage, handlePageChange }: UserListFooterProps) => {
  const pageCount = Math.ceil(totalCount / itemsPerPage);
  const { colorMode } = useColorMode();
  const isCurrentFirst = currentPage === 1;
  const isCurrentLast = currentPage === pageCount;
  const forcePage = currentPage - 1;

  return (
    <>
      <Footer>
        <Text
          sx={{
            fontFamily: 'Helvetica',
            marginLeft: '20px',
            marginTop: '20px',
            marginBottom: '20px',
            fontWeight: '500',
            fontSize: '14px',
            lineHeight: '20.58px',
            letterSpacing: '0.23px',
            color: 'rgba(44, 44, 46, 0.6)',
          }}
        >
          Displaying {forcePage * itemsPerPage + 1} - {Math.min(currentPage * itemsPerPage, totalCount)} of {totalCount}{' '}
          Results.
        </Text>
        <ReactPaginate
          forcePage={forcePage}
          breakLabel={'\u2026'}
          nextLabel={<SideArrow theme={colorMode} disabled={isCurrentLast} right />}
          onPageChange={(selectedItem) => handlePageChange(selectedItem.selected + 1)}
          pageRangeDisplayed={isCurrentFirst || isCurrentLast ? 5 : 3}
          data-testid="pagination"
          pageCount={pageCount}
          marginPagesDisplayed={1}
          previousLabel={<SideArrow theme={colorMode} disabled={isCurrentFirst} />}
          containerClassName={`paginationContainer ${colorMode === 'dark' ? 'dark' : 'light'}`}
        />
      </Footer>
    </>
  );
};

interface SideArrowProps {
  right?: boolean;
  theme: 'light';
  disabled?: boolean;
}

const SideArrow = styled.div<SideArrowProps>`
  width: 8px;
  height: 13px;
  background-image: url(${({ right }) => (right ? Icons.arrowRight : Icons.arrowLeft)});
  cursor: ${({ disabled }) => (!disabled ? 'pointer' : 'default')};
  opacity: ${({ disabled }) => (!disabled ? '1' : '0.4')};
`;

const Footer = styled.footer`
  position: fixed;
  bottom: 0;
  left: 1;
  right: 1;
  width: 77.8%;
  background-color: white;
  display: flex;
`;

export default UserListFooter;
